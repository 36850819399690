.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.PhotoContainer {
  border:#282c34 solid;
  min-height: 10vh;
  min-width: 10vh;
  max-width: 80vh;
  align-items: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;  
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.chakra-accordion .chakra-accordion__item:last-child {
  border-bottom-width: 0;
}

.userfront-secured {
  display: none;
}

.userfront-button-primary {
  background-color: #fb8fff !important;
}

.userfron-error-message {
  display: none;
}

.initialized .userfron-error-message {
  display: block;
}

/* :root {
  --userfront-text-color: #303133;
  --userfront-font-family: "Montserrat, sans-serif";
  --userfront-primary-color: #fb8fff;
} */
